<template>
  <NeoDrawerLayout :showFooter="true">
    <div class="AddManager">
      <a-form-model v-bind="layout" ref="managerForm" :model="formdata" :rules="rules" :colon="false">
        <!-- 姓名 -->
        <a-form-model-item class="mb-3" prop="name" :label="$t('managerInfo.name')">
          <a-input v-model="formdata.name" :placeholder="$t('managerInfo.name_holder')"></a-input>
        </a-form-model-item>
        <!-- 邮箱 -->
        <a-form-model-item class="mb-1" prop="email" :label="$t('managerInfo.email')">
          <a-input v-model="formdata.email" :placeholder="$t('managerInfo.email_holder')" :disabled="edit"></a-input>
          <NeoTips :label="$t('managerInfo.tip_label')" :text="$t('managerInfo.tip_text')" class="tips" />
        </a-form-model-item>
        <!-- 性别 -->
        <a-form-model-item class="mb-1" prop="sex" :label="$t('managerInfo.gender')">
          <a-radio-group v-model="formdata.sex">
            <a-ration v-for="item in gender" :key="item.dictValue" :value="item.dictValue">{{
              item.dictValue
            }}</a-ration>
            <a-radio value="0">{{ $t('managerInfo.male') }}</a-radio>
            <a-radio value="1">{{ $t('managerInfo.female') }}</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 手机 -->
        <a-form-model-item class="mb-3" :label="$t('managerInfo.phone_number')">
          <a-input v-model="formdata.phone" :placeholder="$t('managerInfo.phone_number_holder')"></a-input>
        </a-form-model-item>
        <!-- 部门 -->
        <a-form-model-item class="mb-3" :label="$t('managerInfo.department')">
          <a-input v-model="formdata.department" :placeholder="$t('managerInfo.department_holder')"></a-input>
        </a-form-model-item>
        <a-form-model-item class="mb-3" :label="$t('managerInfo.permission')" prop="seniorManager">
          <a-select v-model="formdata.seniorManager">
            <a-select-option v-for="item in managerList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <template v-slot:footer>
      <a-space :size="10">
        <a-button v-if="edit" type="primary" @click="onEditSubmit">{{ $t('submit') }}</a-button>
        <a-button v-else type="primary" @click="onSubmit" :loading="loading">{{ $t('submit') }}</a-button>
      </a-space>
    </template>
  </NeoDrawerLayout>
</template>

<script>
import NeoTips from '@/components/common/NeoTips'
const { validate } = window.$g

export default {
  name: 'AddManager',
  components: { NeoTips },
  props: ['id', 'edit', 'managerInfo'],
  data() {
    return {
      loading: false,
      layout: {
        labelCol: { flex: '60px' },
        wrapperCol: { flex: 'auto' },
      },
      formdata: {
        name: '',
        email: '',
        sex: '',
        phone: '',
        department: '',
        seniorManager: '',
      },
      rules: {
        name: [validate.required],
        email: [validate.email, validate.required],
        sex: [validate.required],
        seniorManager: [validate.required],
      },
      default: '',
      managerList: [
        {
          value: 1,
          label: this.$t('managerInfo.admin'),
        },
        {
          value: 0,
          label: this.$t('managerInfo.manager'),
        },
      ],
    }
  },
  mounted() {
    if (this.edit) {
      this.getManager()
    }
  },
  computed: {
    gender() {
      return this.$store.getters['app/getDictByType']('GENDER')
    },
  },
  methods: {
    onSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('newManager', {
              agencyId: this.id,
              ...this.formdata,
            })
            this.$message.success(this.$t('prompt.create_success'))
            this.$emit('show')
          } catch (err) {
            this.$httpNotify(err)
          }
        }
      })
    },
    onEditSubmit() {
      this.$refs.managerForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('editManager', {
              agencyId: this.id,
              ...this.formdata,
            })
            this.loading = false
            this.$message.success(this.$t('prompt.edit_success'))
            this.$emit('show')
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    async getManager() {
      try {
        const data = await this.$http({ key: 'getManagerById', params: { id: this.managerInfo.id } })
        this.formdata = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.AddManager {
  /deep/.ant-form-item {
    display: flex;
  }
}
</style>
