<template>
  <NeoPageLayout>
    <div class="personnel-admin">
      <!-- 下单经理 -->
      <div class="manager">
        <div class="btn-container">
          <a-button type="primary" class="mr-2" @click="addManagerVisible = true">{{
            $t('companyInfo.btn_new_manager')
          }}</a-button>
          <NeoUploadButton
            :title="$t('companyInfo.btn_bulk_import')"
            v-model="managerFileList"
            :showNote="false"
            :showFileList="false"
            :neoStyle="true"
            accept=".xlsx"
            maxSize="5"
          />
          <a-button
            type="link"
            href="https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/order_customer_info_template.xlsx"
          >
            {{ $t('companyInfo.btn_download') }} <a-icon type="download" />
          </a-button>
        </div>
        <!-- 下单经理列表 table -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          bordered
          :pagination="managerPagination"
          :data-source="managerInfo"
          :loading="managerLoading"
        >
          <!-- 姓名 -->
          <a-table-column :title="$t('managerInfo.name')" key="name" align="center" :width="150">
            <template slot-scope="text">
              {{ text.name | defined }}
            </template>
          </a-table-column>
          <!-- 邮箱 -->
          <a-table-column :title="$t('managerInfo.email')" key="email" :width="200">
            <template slot-scope="text">
              {{ text.email | defined }}
            </template>
          </a-table-column>
          <!-- 手机 -->
          <a-table-column :title="$t('managerInfo.phone_number')" key="phone" :width="200">
            <template slot-scope="text">
              {{ text.phone === '' ? '---' : text.phone | defined }}
            </template>
          </a-table-column>
          <!-- 部门 -->
          <a-table-column :title="$t('managerInfo.department')" key="department">
            <template slot-scope="text">
              {{ text.department === '' ? '---' : text.department | defined }}
            </template>
          </a-table-column>
          <!-- 状态 -->
          <a-table-column :title="$t('managerInfo.statu')" key="status" align="center">
            <template slot-scope="text">
              {{ text.status ? $t('managerInfo.job') : $t('managerInfo.departure') | defined }}
            </template>
          </a-table-column>
          <!-- 权限 -->
          <a-table-column :title="$t('managerInfo.senior_manager')" key="seniorManager">
            <template slot-scope="text">
              {{ text.seniorManager ? $t('managerInfo.admin') : $t('managerInfo.manager') | defined }}
            </template>
          </a-table-column>
          <!-- 加入时间 -->
          <a-table-column :title="$t('managerInfo.join_time')" key="createDate">
            <template slot-scope="text">
              {{ text.createDate | dateFormat }}
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column :title="$t('companyInfo.action')" key="action">
            <template slot-scope="record">
              <!-- 编辑下单经理 -->
              <a class="mr-3" @click="onEditManager(record)">{{ $t('companyInfo.action_edit') }}</a>
              <!-- 注销下单经理 -->
              <a @click="onLogout(record)">{{
                record.status ? $t('managerInfo.set_departure') : $t('managerInfo.set_jon')
              }}</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <!-- 新增下单经理侧抽屉 -->
      <a-drawer
        width="460px"
        :title="managerEdit ? $t('companyInfo.edit_manager_title') : $t('companyInfo.new_manager_title')"
        placement="right"
        :visible="addManagerVisible"
        @close=";(addManagerVisible = false), (managerEdit = false)"
      >
        <AddManager
          v-on:show="editManagerInfo"
          :id="id"
          v-if="addManagerVisible"
          :managerInfo="selectedManager"
          :edit="managerEdit"
        />
      </a-drawer>
    </div>
  </NeoPageLayout>
</template>

<script>
import AddManager from '../agency/agencyInfo/components/AddManager'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import { mapState } from 'vuex'

export default {
  name: 'personnel',
  components: {
    AddManager,
    NeoUploadButton,
  },
  data() {
    return {
      managerInfo: [],
      managerLoading: false,
      managerPagination: {
        current: 1,
        total: 0,
        size: 'middle',
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => {
          this.managerPagination.current = 1
          this.managerPagination.pageSize = pageSize
          this.getManagerInfo()
        },
        onChange: (page) => {
          this.managerPagination.current = page
          this.getManagerInfo()
        },
      },
      managerFileList: [],
      managerEdit: false,
      addManagerVisible: false,
      selectedManager: {},
    }
  },
  watch: {
    managerFileList(newFile) {
      const file = newFile[newFile.length - 1] || {}
      if (file.status === 'done') {
        const data = this.importManager(file.url)
        data.then((value) => {
          if (value) {
            this.getManagerInfo()
          }
        })
      }
    },
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),
    id() {
      return this.$store.state.app.userInfo.agencyId
    },
  },
  mounted() {
    this.getManagerInfo()
  },
  methods: {
    // 根据客户实体获得下单经理信息
    async getManagerInfo() {
      try {
        this.managerLoading = true
        const data = await this.$http('managerInfo', {
          limit: this.managerPagination.pageSize,
          page: this.managerPagination.current,
          agencyId: this.id,
        })
        this.managerLoading = false
        this.managerInfo = data.list
        this.managerPagination.total = data.total
      } catch (err) {
        this.managerLoading = false
        this.$httpNotify(err)
      }
    },
    // 下单经理子组件触发父组件
    editManagerInfo() {
      this.getManagerInfo()
      this.addManagerVisible = false
      this.managerEdit = false
    },
    // 导入下单经理
    async importManager(val) {
      try {
        const data = await this.$http('importManager', {
          agencyId: this.id,
          fileUrl: val,
        })
        return data
      } catch (err) {
        // this.$message.error(err.msg)
        // TODO：修改
        this.$httpNotify(err)
      }
    },
    // 编辑下单经理
    onEditManager(record) {
      this.selectedManager = record
      this.addManagerVisible = true
      this.managerEdit = true
    },
    // 下单经理-注销操作响应事件
    async onLogout(value) {
      const s = value.status ? 0 : 1
      try {
        const data = await this.$http({
          key: 'setStatus',
          query: {
            id: value.id,
            status: s,
          },
        })
        if (data) {
          this.getManagerInfo()
          this.$message.success(this.$t('prompt.set_success'))
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.personnel-admin {
  padding: 10px 20px 0;
  .btn-container {
    display: flex;
    margin: 20px 0;
  }
}
</style>
